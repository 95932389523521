import { Alcatraz } from './alcatraz';
import { Flyout } from './flyout';
import { BlogLayout } from './blog-layout';
import { TitleTreatment } from './title-treatment';
import { AccordionToggle } from './accordion-toggle';
import { LandingScroll } from './landing-scroll';

/**
 * Initialization JS.
 *
 * This file initializes all theme functionality including all things that need to fire when
 * the DOM is ready or when the page is fully loaded.
 *
 * @since  1.0.0
 */

(function() {
  // When the DOM is ready, initialize all the things.
  document.addEventListener(
    'DOMContentLoaded',
    () => Alcatraz.Nav.initSiteNavigation(),
    Flyout.initFlyout(),
    BlogLayout.initBlogLayout(),
    TitleTreatment.initTreatment(),
    AccordionToggle.initAccordion(),
    LandingScroll.initLanding()
  );

  // Reset the primary nav when a Customizer partial refresh happens.
  document.addEventListener('customize-preview-menu-refreshed', () =>
    Alcatraz.Nav.initPrimaryNavigation()
  );
})();
