import { scrollIntoView } from 'scroll-js';

export const LandingScroll = (function() {
  const body = document.querySelector('body');

  const checkCookie = () => {
    return document.cookie
      .split(';')
      .filter(item => item.trim().startsWith('ct_landing_visit')).length;
  };

  const setFirstVisitCookie = () => {
    let expires = '';
    const date = new Date();
    date.setTime(date.getTime() + 10 * 60 * 1000); // 10 minutes
    expires = '; expires=' + date.toUTCString() + ';';
    document.cookie = 'ct_landing_visit=true' + expires + ';path=/';
  };

  const clickToScroll = () => {
    const page = document.querySelector('#page');

    scrollIntoView(page, { duration: 150 });
  };

  const initLanding = () => {
    if (!body.classList.contains('home')) {
      return;
    }

    const repeatVisit = checkCookie();
    const pointer = document.querySelector('#landing-scroll');

    if (1 !== repeatVisit) {
      setFirstVisitCookie();
    }

    if (null !== pointer) {
      pointer.addEventListener('click', clickToScroll);
    }
  };

  /**
   * Expose public methods.
   */
  return {
    initLanding: initLanding
  };
})();
