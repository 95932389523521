export const TitleTreatment = (function() {
  const colorTheTees = () => {
    const title = document.querySelector('.site-title');
    const titleLink = document.querySelector('.site-title a');
    const titleText = title.innerText;
    let newTitle = '';

    [...titleText].forEach(letter => {
      if ('T' === letter) {
        letter = `<span>${letter}</span>`;
      }
      newTitle += letter;
    });

    titleLink.innerHTML = newTitle;
  };

  const initTreatment = () => {
    window.onload = colorTheTees();
  };

  /**
   * Expose public methods.
   */
  return {
    initTreatment: initTreatment
  };
})();
