export const BlogLayout = (() => {
  function resizeGridItem(item) {
    const grid = document.querySelector('.posts-container');
    const rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
    );
    const rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue('grid-row-gap')
    );

    // Get the height of the content, then divide it by the grid row height and
    // gap to get the number of rows the content should span.
    const rowSpan = Math.ceil(
      (item.querySelector('.post-content-container').getBoundingClientRect()
        .height +
        rowGap) /
        (rowHeight + rowGap)
    );

    item.style.gridRowEnd = 'span ' + rowSpan;
  }

  function resizeAllGridItems(grid) {
    const allItems = grid.querySelectorAll('article');
    for (let x = 0; x < allItems.length; x++) {
      resizeGridItem(allItems[x]);
    }
  }

  function resizeInstance(instance) {
    const item = instance.elements[0];
    resizeGridItem(item);
  }

  const allItems = document.getElementsByClassName('.posts-container .post');
  for (let x = 0; x < allItems.length; x++) {
    imagesLoaded(allItems[x], resizeInstance);
  }

  const initBlogLayout = () => {
    const grid = document.querySelector('.posts-container');
    if (grid) {
      window.onload = resizeAllGridItems(grid);
      window.addEventListener('resize', function() {
        resizeAllGridItems(grid);
      });
    }
  };

  /**
   * Expose public methods.
   */
  return {
    initBlogLayout: initBlogLayout
  };
})();
