export const AccordionToggle = (function() {
  const showNextPara = (nextPara, toggle) => {
    nextPara.classList.toggle('toggle-hidden');
    nextPara.classList.toggle('toggle-open');
    toggle.classList.toggle('accordion-toggle--active');
  };

  const accordionToggle = () => {
    const toggles = document.querySelectorAll('.accordion-toggle');

    if (toggles) {
      for (let toggle of toggles) {
        const prevPara = toggle.previousSibling;
        const nextPara = toggle.nextSibling;

        prevPara.classList.add('toggle-previous');
        nextPara.classList.add('toggle-hidden');

        toggle.addEventListener(
          'click',
          function() {
            showNextPara(nextPara, toggle);
          },
          false
        );
      }
    }
  };

  const initAccordion = () => {
    window.onload = accordionToggle();
  };

  /**
   * Expose public methods.
   */
  return {
    initAccordion: initAccordion
  };
})();
