export const Flyout = (function() {
  const body = document.querySelector('body');
  const toggleButton = document.querySelector('.button--menu-toggle');
  const flyoutNav = document.querySelector('.flyout-nav');

  const toggleMenuClass = () => {
    if (body.classList.contains('flyout-open')) {
      body.classList.remove('flyout-open');
    } else {
      body.classList.add('flyout-open');
    }
  };

  const initFlyout = () => {
    flyoutNav.classList.remove('flyout-hidden');
    toggleButton.addEventListener('click', toggleMenuClass);
    flyoutNav.addEventListener('click', toggleMenuClass);
  };

  /**
   * Expose public methods.
   */
  return {
    initFlyout: initFlyout
  };
})();
